<template>
  <div class="content" v-loading="loadingStatus.getTable">
    <div>
      <el-form ref="staffRef" size="medium" :model="dataForm" label-width="90px">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="所属工会：">
              <el-select style="width: 100%;" v-model="dataForm.presidentId" placeholder="请选择" clearable filterable @change="presidentIdChange">
                <el-option v-for="(item ) in presidentList" :key="item.id"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="小组：">
              <el-select style="width: 100%" v-model="dataForm.groupId" placeholder="请选择" clearable @change="groupIdChange">
                <el-option v-for="(item ) in groupList" :key="item.id"
                           v-show="item.presidentId === dataForm.presidentId || !dataForm.presidentId"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="员工：">
              <el-select style="width: 100%" v-model="dataForm.stuffId" placeholder="请选择" clearable>
                <el-option v-for="(item) in empList" :key="item.id"
                           v-show="item.groupId=== dataForm.groupId || !dataForm.groupId"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="游戏：">
              <el-select clearable style="width: 100%" v-model="dataForm.gameId">
                <el-option v-for="(item) in gamesList" :key="item.id"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="账号：">
              <el-input clearable style="width: 100%" v-model="dataForm.gameUserName" placeholder="请输入">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="角色名：">
              <el-input clearable v-model="dataForm.gameRole" placeholder="请输入角色名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="日期：">
              <el-date-picker
                  clearable
                  type="daterange"
                  style="width: 100%"
                  range-separator="至"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  v-model="time"
                  @change ="getTime"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <div style="display: inline-block;padding-left: 10px;padding-bottom: 20px">
            <el-button type="primary" @click="getTable" size="medium">查询</el-button>
            <el-button plain size="medium" @click="getReset">重置</el-button>
            <el-button type="warning" @click="onExport"  size="medium">导出</el-button>
          </div>
        </el-row>

      </el-form>
    </div>
<!--    <div class="con-tab">
      <div>
        角色创建数：0
      </div>
      <div style="padding-left: 20px;">
        设备数：0
      </div>
    </div>-->
    <div>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column prop="presidentName" width="140" label="所属公会" align="center"></el-table-column>
          <el-table-column prop="stuffName" label="员工名称" align="center"></el-table-column>
<!--        <el-table-column prop="promotionId" label="推广ID" align="center"></el-table-column>-->
        <el-table-column prop="gameName" label="游戏" align="center"></el-table-column>
        <el-table-column prop="gameZone" label="区服" align="center"></el-table-column>
        <el-table-column prop="gameRole" label="角色" align="center"></el-table-column>
        <el-table-column prop="gameUserLevel" label="等级" align="center"></el-table-column>
       <!-- <el-table-column prop="gameUserId" label="用户ID" align="center"></el-table-column> -->
        <el-table-column prop="gameUsername" label="账号" align="center"></el-table-column>
        <el-table-column prop="registeredDatetime" width="140px" label="注册时间" align="center"></el-table-column>
        <el-table-column prop="updatedDatetime" width="140px" label="更新时间" align="center"></el-table-column>
<!--        <el-table-column prop="groupId" label="小组ID" align="center"></el-table-column>-->
<!--        <el-table-column prop="stuffId" label="员工ID" align="center"></el-table-column>-->
        <el-table-column prop="groupName" label="小组名称" align="center"></el-table-column>
        <!-- <el-table-column prop="amount" label="充值金额" align="center"></el-table-column> -->
          <el-table-column prop="registerIp" width="140px" label="注册IP" align="center"></el-table-column>
          <el-table-column prop="ipDevice" width="160px" label="注册设备" align="center"></el-table-column>
      </el-table>
      <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  commonInfo,
  dataDetailExtensionDetailList, dataStatisticsRegisterDetailList,
} from "../../service/moudules/promotionLinkApi";
import Excel from "@/util/excelGenerator"
import loadingStatusMixin from "@/mixins/loadingStatus";

export default {
  name: "promotionDetail",
  mixins: [loadingStatusMixin],

  data (){
    return {
      dataForm: {
        groupId: '', //组ID
        stuffId: '', //员工ID
        gameId: '', //游戏ID
        startDate: '', //开始时间
        gameRole: '', //角色名称
        endDate: '', //结果时间
        gameUserId: '', //用户ID
        presidentId: '',
        gameUserName:''
      },
      time: [],
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      groupList: [], //小组
      empList: [], //员工信息
      gamesList: [], //游戏
      userList: [],
      presidentList: []
    }
  },
  mounted() {
    // dataDetailExtensionDetailList
    this.getTable();
    this.getCommonInfo();
  },
  methods:{
    presidentIdChange(value) {
        this.dataForm.groupId = ''
        this.dataForm.stuffId = ''
    },
    groupIdChange(value) {
        this.dataForm.stuffId = ''
    },
    async getCommonInfo() {
      let res = await commonInfo();
      if (res.code == 200) {
        this.groupList = res.data.groupList;
        this.empList = res.data.empList;
        this.gamesList = res.data.gamesList;
        this.userList = res.data.userList;
        this.presidentList = res.data.presidentList;
      }
    },
    async getTable() {
      if ( this.hasLoadingState('getTable') ) return;
      try {
        this.addLoadingState('getTable');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await dataDetailExtensionDetailList(params);
        this.removeLoadingState("getTable");
        if ( res.code == 200) {
          this.tableData = res.data.records;
          // this.page.pageNo = res.data.pages;
          this.page.pageNo = res.data.current;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState("getTable");
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState("getTable");
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
    getTime(val) {
      if (val && val.length > 0 ) {
        let [x, y] = val;
        this.dataForm.startDate = x+' 00:00:00';
        this.dataForm.endDate = y+' 23:59:59';
      }else {
        this.dataForm.startDate = '';
        this.dataForm.endDate = '';
      }
    },
    getReset() {
      this.dataForm = {
        groupId: '', //组ID
        stuffId: '', //员工ID
        gameId: '', //游戏ID
        startDate: '', //开始时间
        gameRole: '', //角色名称
        endDate: '', //结果时间
        gameUserId: '', //用户ID
        presidentId: ''
      };
      this.time = [];
      this.getTable();
    },
    async onExport() {
      if (this.hasLoadingState("onExport")) return;
      try {
        const params = {
          pageNo: 1,
          pageSize: this.page.total
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await dataStatisticsRegisterDetailList(params);
        if (res.code === 200) {
          const columns = [
            { title: "序号", dataIndex: "index" },
            { title: "所属公会", dataIndex: "presidentName" },
            { title: "推广ID", dataIndex: "promotionId" },
            { title: "游戏", dataIndex: "gameName" },
            { title: "区服", dataIndex: "gameZone" },
            { title: "角色", dataIndex: "gameRole" },
            { title: "等级", dataIndex: "gameUserLevel" },
            { title: "用户ID", dataIndex: "gameUserId" },
            { title: "帐号", dataIndex: "gameUsername" },
            { title: "注册IP", dataIndex: "registerIp" },
            { title: "注册设备", dataIndex: "ipDevice" },
            { title: "注册时间", dataIndex: "registeredDatetime" },
            { title: "更新时间", dataIndex: "updatedDatetime" },
            { title: "小组", dataIndex: "groupName" },
            { title: "员工", dataIndex: "stuffName" },
            { title: "充值金额", dataIndex: "amount" },
          ];
          const formData = (rawData) => {
            const data = rawData.map((item, index) => {
              if(!item.amount){
                item.amount = '0'
              }
              const temp = {
                index: index + 1,
                presidentName: item.presidentName,
                gameName: item.gameName,
                gameZone: item.gameZone,
                gameRole: item.gameRole,
                gameUserLevel: item.gameUserLevel,
                gameUserId: item.gameUserId,
                gameUsername: item.gameUsername,
                registerIp: item.registerIp,
                ipDevice: item.ipDevice,
                registeredDatetime: item.registeredDatetime,
                updatedDatetime: item.updatedDatetime,
                groupName: item.groupName,
                stuffName: item.stuffName,
                promotionId: item.promotionId,
                amount: item.amount,
              };
              return temp;
            });
            return data;
          };
          const data = formData(res.data.records);
          const excel = new Excel({
            columns,
            data: data,
            tablename: "推广明细报表",
          });
          await excel.downFile('csv');
        }
      } catch (err) {
        console.error(err);
      }
    },
  },

}
</script>

<style scoped lang="less">
.content{
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .con-tab{
    background-color: #e6a23c2b;
    padding: 15px 10px;
    margin: 20px 0;
    border-radius: 8px;
    font-size: 14px;
    color: #e6a23c;
    display: flex;
  }
  .page {
    text-align: right;
    margin-top: 15px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar{
    width: 1px;
    height: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: rgba(0,0,0,.2);
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track{
      border-radius: 10px;
    background-color: rgba(0,0,0,.1);
  }
  ::v-deep .el-table__header-wrapper .el-table__cell{
    height:50px;
    background-color:#fafafa;
    color:#333333;
    padding:0
  }
}

</style>